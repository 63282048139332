<template>
  <div class="overflow-hidden">
    <div
      v-if="isRouteShown"
      class="absolute z-30 top-5 left-5 bg-white right-5 bottom-5 grid grid-cols-2 overflow-y-auto overflow-x-hidden rounded-xl ml-8 left-10"
      style="width: 500px; grid-template-rows: auto auto auto 1fr; height: 95vh"
    >
      <div class="mx-auto" style="width: 500px" @scroll="handleScroll">
        <div
          class="cont h-28 bg-white w-full z-50 inset-0 pt-3 px-8 transition-all ease-out duration-500 sticky"
          style="width: 100%"
        >
          <div class="flex justify-between items-center pb-4 mt-4">
            <div class="font-bold text-darkblue text-xl">
              {{ $t('routesTrips.route.create') }}
            </div>
            <i
              class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
              @click="
                $emit('close'),
                  cleanMap(),
                  $store.commit('geozones/END_DRAWING')
              "
            />
          </div>
          <div
            class="flex justify-end items-center bg-white mb-6"
            :class="scrolled ? 'shadow' : ''"
          >
            <skif-button
              class="mr-2 w-full float-right"
              variant="normal"
              @click="$emit('close'), cleanMap()"
            >
              {{ $t('btn.cancel') }}
            </skif-button>
            <skif-button
              class="w-full float-right"
              @click="
                save()
                $emit('my-done'), cleanMap()
              "
            >
              {{ $t('add') }}
            </skif-button>
          </div>
          <div class="bg-white flex justify-start items-center pb-4 mt-4">
            <label for="name"></label>
            <input
              id="name"
              v-model="routeName"
              class="input outline-none pb-2"
              type="text"
              style="width: 450px; height: 35px"
              :placeholder="$t('routesTrips.route.name.placeholder')"
              :class="isEmpty ? 'isEmpty' : ''"
            />
          </div>

          <div
            class="flex border border-t-0 border-l-0 border-r-0 pb-2 justify-center items-centerpb-3 bg-white mb-6"
            :class="scrolled ? 'shadow' : ''"
          >
            <button
              type="button"
              class="transparentBtn ml-4 mr-4"
              @click="
                ;(isGeozoneCreating = true),
                  (isRouteShown = false),
                  $store.commit('geozones/START_DRAWING')
              "
            >
              {{ $t('routesTrips.route.addFromMap') }}
            </button>
            <button
              type="button"
              class="transparentBtn ml-4 mr-4"
              @click=";(isAddFromGeozones = true), (isRouteShown = false)"
            >
              {{ $t('routesTrips.route.addFromGeozones') }}
            </button>
          </div>
          <div class="flex items-center justify-center">
            <table v-if="filteredGeozone.length" style="width: 100%">
              <thead>
                <tr>
                  <th class="ml-2"></th>
                  <th class="z-10 w-10 top-0" align="left">
                    <skif-checkbox-old
                      @click.native="(event) => event.stopPropagation()"
                      v-model="isAllGeozonesChecked"
                      :indeterminate="isIndeterminate"
                      v-if="filteredGeozone.length > 0"
                      :disabled="!filteredGeozone.length"
                    />
                  </th>
                  <th
                    class="white fontLato top-0 mb-2"
                    style="width: 220px"
                    align="left"
                  >
                    <span
                      class="text-darkblue leading-10 cursor-pointer mr-2"
                      style="font-size: 14px; color: #3c5e8f"
                    >
                      {{ $t('routesTrips.route.point') }}
                    </span>
                  </th>
                  <th class="fontLato top-0" style="width: 80px">
                    <transition name="fade">
                      <span
                        v-if="geozonesSelected.length > 0"
                        class="h-9 w-9 text-darkblue leading-10 cursor-pointer group relative"
                        style="font-size: 14px; color: #91b4e7"
                        @click="deletePoints()"
                      >
                        <transition name="fade">
                          <button
                            class="h-9 w-9 group relative link"
                            type="button"
                            style="margin-top: 0px"
                          >
                            <svg
                              class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
                              width="24"
                              height="24"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#91B4E7"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
                              />
                            </svg>
                            <skif-badge
                              list="list"
                              :value="geozonesSelected.length"
                            />
                          </button>
                        </transition>
                      </span>
                    </transition>
                  </th>
                </tr>
              </thead>
              <draggable
                v-model="points"
                tag="tbody"
                move-class="transition duration-1000"
                style="border-top: 4px solid white"
              >
                <tr
                  class="cursor-pointer hover_bg-hoverrow"
                  v-for="(geo, index) in filteredGeozone"
                  :key="index"
                  @click="drawGeozone(geo)"
                >
                  <td class="w-8 pl-4 pr-4 mr-2" style="color: #3c5e8f">
                    {{ index + 1 }}
                  </td>
                  <td class="w-10">
                    <skif-checkbox-old
                      type="checkbox"
                      v-model="geozonesSelected"
                      :label="geo.id"
                      @click.native="(event) => event.stopPropagation()"
                    />
                  </td>
                  <td class="ellipsis" align="left" style="height: 50px">
                    <input
                      class="input outline-none pb-2"
                      type="text"
                      v-model="geo.name"
                    />
                  </td>
                  <td class="pr-2" align="right">
                    <img src="../../../assets/icons/move.svg" />
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </div>
      </div>
    </div>
    <transition name="dialog-fade">
      <addFromGeozones
        v-if="isAddFromGeozones"
        @close=";(isAddFromGeozones = false), (isRouteShown = true)"
        @my-done="fromGeozones"
      />
    </transition>
    <transition name="dialog-fade">
      <createGeozones
        v-if="isGeozoneCreating"
        @close=";(isGeozoneCreating = false), (isRouteShown = true)"
        @my-done="createdGeozone"
      />
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'CreateRoute',
  components: {
    draggable,
    addFromGeozones: () =>
      import('@/components/race/routes/addFromExistingGeo'),
    createGeozones: () => import('@/components/race/routes/createNewGeo')
  },

  data() {
    return {
      routeName: '',
      isRouteShown: true,
      isAddFromGeozones: false,
      isGeozoneCreating: false,
      geozonesSelected: [],
      isRoutes: true,
      scrolled: false,
      search: '',
      isInArray: false,
      points: [],
      isEmpty: false,
      isAllValid: false,
      pointToDelete: []
    }
  },
  computed: {
    ...mapState('routes', {
      routes: (state) => state.routes,
      existingGeozones: (state) => state.existingGeozones,
      newGeozones: (state) => state.newGeozones,
      status: (state) => state.status
    }),
    isAllGeozonesChecked: {
      get() {
        return (
          this.geozonesSelected.length >= this.filteredGeozone.length &&
          this.filteredGeozone.length
        )
      },
      set(value) {
        this.geozonesSelected = value ? this.geozonesId : []
      }
    },
    geozonesId() {
      return this.filteredGeozone.map((k) => k.id)
    },

    filteredGeozone() {
      return this.points.filter((geozone) => {
        if (!geozone.name) return geozone
        return geozone.name.toLowerCase().match(this.search.toLowerCase())
      })
    },
    isIndeterminate() {
      return (
        this.geozonesSelected.length > 0 &&
        this.geozonesSelected.length < this.filteredGeozone.length
      )
    }
  },
  methods: {
    ...mapActions({
      createRoute: 'routes/CREATE_ROUTE'
    }),
    ...mapMutations({
      setStatus: 'routes/setStatus'
    }),
    cleanMap() {
      this.$store.commit('routes/removeLastPoint', this.newGeozones)
    },
    fromGeozones() {
      this.points.push(
        ...this.existingGeozones.map((item) => {
          return { ...item }
        })
      )
    },
    createdGeozone(geozone) {
      console.log('geozone', geozone)
      // Проверка на создание геозоны, если нет делаем return
      if (!this.newGeozones) return
      this.points.push(this.newGeozones)
    },
    async save() {
      console.log('create route vue')
      const component = this
      const points = this.filteredGeozone.map((point) => {
        return {
          name: point.name,
          type: {
            key: point.type.key,
            type: 'geozone_type'
          },
          width: point.width === undefined ? '' : point.width,
          geometry: point.geometry
        }
      })

      const route = {
        name: !this.routeName.length
          ? component.$showError('Введите имя!')
          : this.routeName,
        points: !points.length
          ? component.$showError(' Выберите хотя бы одну точку')
          : points
      }

      if (this.routeName.length && this.points.length) {
        await this.createRoute(route)

        if (this.status === 200) {
          this.$emit('close')
          this.setStatus('')
        }
      }
    },
    drawGeozone(geo) {
      this.$store.commit('routes/draw_geo', geo)
    },
    handleScroll(a) {
      this.scrolled = a.target.scrollTop > 0
    },
    deletePoints() {
      let pointToDelete = []
      this.geozonesSelected.forEach((item) => {
        this.points.splice(
          this.points.findIndex(function (i) {
            if (i.id === item) {
              pointToDelete.push(i)
            }
            return i.id === item
          }),
          1
        )
      })

      this.$store.commit('routes/geo_to_delete', pointToDelete)
      pointToDelete = []

      this.geozonesSelected = []
    }
  }
}
</script>
<style scoped>
.isEmpty {
  border: 1px solid rgb(247, 66, 66) !important;
}

.input {
  background-color: #fefefe;
  border-radius: 4px;
  height: 18px;
  padding: 15px;
  font-weight: bold;
  font-size: 13px;
  font-family: 'Lato', sans-serif;
  color: #677785;
  font-size: 13px;
  border: 1px solid #cfdbeb;
  width: 90%;
}

.buttons {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #91b4e7;
  mix-blend-mode: normal;
}

.buttons:focus {
  outline: none;
}

.isActive {
  color: #3c5e8f;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.transparentBtn {
  color: #5477a9;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 3px 2px;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
  font-family: Source Sans Pro;
  font-style: normal;
  line-height: 16px;
}

.transparentBtn:focus {
  outline: none;
}

.transparentBtn:hover {
  color: #3c5e8f;
}
</style>
